import React, { Component } from 'react';
import './Home.css'
import { ThetaDropConnect } from '@thetalabs/theta-drop-connect';
import axios from 'axios';



//new info
const infoID = 'dapp_qvspk7g05yjmxqmj0ud3r9ewgt6';
const redirectURLNew = 'https://game.cykoko.tv/'
const TVCardNFT = 'type_erwr3i0cwikugzdy4y6tugrri59';

//heads ID
//type_jgzuu9ypadin368adt3mtdix3wi


//first drop ID


//tv NFT
//type_erwr3i0cwikugzdy4y6tugrri59



//goal is to make it simple
//1 button, connect
//welcome "user
// then shows button"


//make sure button doesn't turn on utnil both checks are done


///nft/user_b12yxgihkeupv26zz7sifvy4fb4/list?expand=content_id&content_id=type_erwr3i0cwikugzdy4y6tugrri59

//Unity Needs
//CykoOriginalNFTCards <- Check
//ThetaDropUserName  <- Check
//TVNFTs <-??
//walletAddress<- Check


//OBject vs array?

//TODO makesure it can "pull" form session storeage
//design ui
//make sure loading tiems are correct
//test
//deploy

class Home extends Component {

  constructor(props) {
    super(props);
    this.thetaDrop = new ThetaDropConnect();

    this.state = {
      thetaDropUsername: '0x0',
      tpsId: null,
      profilePicture: null,
      loading: false,
      statusCode: 0, //Not connected // 1 Don't Own Card //2 Ready To Play //3 Connection Error
      targetRequests: 0, // the link is a redirect there should be a total of 3, 1 for user info, 1 for NFTs and 1 for OGNFTs
      currentRequestCount: 0

    }

  }

  componentDidMount() {
    this.finishConnectViaRedirect();
  }


  finishConnectViaRedirect = async () => {
    const result = await this.thetaDrop.finishConnectViaRedirect();

    if (result) {  //returns false if there is none, IE function rest of function does not run
      this.setState({ targetRequests: 3 });
      this.setState({ loading: true });
      const { snsId, oauth2Token } = result;

      //window.sessionStorage.setItem('oauth2Token', oauth2Token);

      this.setState({
        tpsId: snsId,
        authToken: oauth2Token
      });

      this.refreshUser(); //get user info
      this.refreshOwnershipChecks(); //check if they own NFT

    }
  }



  refreshUser = async () => {
    const userData = await this.thetaDrop.fetchUser();
    this.setState({ userData });
    this.setState({ thetaDropUsername: userData.username });
    this.setState({ pfp: userData.avatar_url });

    window.sessionStorage.setItem('cykokoTVUserData', JSON.stringify(userData));
    window.sessionStorage.setItem('walletAddress', this.state.tpsId);
    window.sessionStorage.setItem('ThetaDropUserName', userData.username);

    this.checkForDownloadCount();
  }

  refreshOwnershipChecks = async () => {

    const filters = {
      content_id: TVCardNFT
    };

    const userInfo = await this.thetaDrop.fetchUserNFTs(filters);
    if (userInfo.nfts !== undefined) {
      let links = '';
      userInfo.nfts.forEach(element => {
        let link = `https://api.thetadrop.com/type/type_erwr3i0cwikugzdy4y6tugrri59.json?nft_id=${element.id}`;
        if (links !== '') links += "," + link;
        else links += link
      });

      sessionStorage.setItem("TVNFTs", links);
    }

    const isOwner = await this.thetaDrop.checkUserIsOwner(filters);

    this.setState({
      isOwner: isOwner
    });

    this.checkForDownloadCount();
    this.CheckForOrignalNFTs();

  }

  CheckForOrignalNFTs = async () => {
    const result = await axios.get(`https://api.thetadrop.com/nft/${this.state.tpsId}/list?number=1000&expand=token_id&expand=content_id&collection_id=coll_wh01k7q8zv4878qvq8z9exc9s47`);
    let OGNFTs = 0;
    if(result.data.body.pagination.count !== 0){
      Object.values(result.data.body.types).forEach(element => {
        if (element['collection_id'] === "coll_wh01k7q8zv4878qvq8z9exc9s47") {
          OGNFTs++;
        }
      })
    }

    this.setState({ OGNFTs })
    window.sessionStorage.setItem('CykoOriginalNFTCards', OGNFTs);

    this.checkForDownloadCount();
  }

  checkForDownloadCount = () => {
    let currentDownloads = this.state.currentRequestCount;
    currentDownloads++;
    this.setState({ currentRequestCount: currentDownloads })

    if (this.state.currentRequestCount >= this.state.targetRequests) {
      this.setState({ loading: false });
      if (this.state.isOwner) this.setState({ statusCode: 2 })
      else this.setState({ statusCode: 1 })
    }

  }

  connectToThetaDropViaRedirect = async () => {
    this.setState({ loading: true });
    this.thetaDrop.connectViaRedirect(infoID, redirectURLNew);
  };

  playGame(e) {
    e.preventDefault();
    window.location.replace('./play');
  }


  render() {
    return (
      <div className='home'>
        <div className='home-image-container'>
          <img src='./TV_Star.png' alt='main-logo'></img>
        </div>

        {
          this.state.loading &&
          <p>Loading...</p>
        }
        {
          (this.state.statusCode === 0 && !this.state.loading) &&
          <button onClick={() => { this.connectToThetaDropViaRedirect() }}>CONNECT MY CYKO HEADZ</button>
        }

        {
          (this.state.statusCode === 1 && !this.state.loading) &&
          <p>Don't Own Card</p>
        }

        {
          (this.state.statusCode === 2 && !this.state.loading) &&
          <button onClick={(e) => this.playGame(e)}>Play</button>
        }

      </div>

    )
  }
}

export default Home;
