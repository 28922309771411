import React from "react";
import { Unity, useUnityContext } from "react-unity-webgl";


const checkForStoreage = () =>{
    if(sessionStorage.getItem("TVNFTs") == null ){
       window.location.replace('https://www.thetadrop.com/set/coll_m50pm16tjwm7v649f69rsae14km');
    }
 }

 
let testvar = 14;

function Game() {
    const { unityProvider, isLoaded, loadingProgression } = useUnityContext({
        loaderUrl: "./../game/Mix.loader.js",
        dataUrl: "./../game/Mix.data",
        frameworkUrl: "./../game/Mix.framework.js",
        codeUrl: "./../game/Mix.wasm",
    });
  

    checkForStoreage();
    const loadingPercentage = Math.round(loadingProgression * 100);

    const ratio = window.innerWidth / window.innerHeight;
    
    if (ratio < 1) {  ///Portrait
        return (
                <div style={{textAlign:"center"}}>
                <h1 style={{color: "white"}}>Error! Game is only meant to be played in landscape mode. Please rotate your device and refresh the page. </h1>
                <h2 style={{color: "white", fontSize:"18px"}}>Also once you're in the game, it's recommended you enable FullScreen Mode for the best experince.</h2>
              </div>

          );
    } else {
        return (     //Good to go, device is landscape
            <div style={{ width: '100%', background: 'black' }}>
                {isLoaded === false && (
                    // We'll conditionally render the loading overlay if the Unity
                    // Application is not loaded.
                    <div className="loading-overlay">
                        <p>Loading... ({loadingPercentage}%)</p>
                    </div>
                )}
                <Unity unityProvider={unityProvider} style={{ width: '100%', height: '99vh' }} />
            </div>
        );
    }
  }

export default Game;
